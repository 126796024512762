import acpVirtualCardsTemplate from './templates/acp-virtual-cards-landing.html';
import acpvirtualCardsAddDialogTemplate from './templates/acp-virtual-cards-add-dialog.html';
import acpvirtualCardsAddBottomSheetTemplate from './templates/acp-virtual-cards-add-bottom-sheet.html';
import acpVirtualCardsDetailBottomSheetTemplate from './templates/acp-virtual-cards-detail-bottom-sheet.html';
import acpVirtualCardsDetailDialogTemplate from './templates/acp-virtual-cards-detail-dialog.html';

// @ngInject
function AcpVirtualCardsComponentCtrl(
  nsComponentDecorator,
  acpVirtualCardsModel,
  nsInPageFlow,
  content,
  acpCoreDispatcher,
  ACP_VIRTUAL_CARDS_CONSTANTS,
  $mdDialog,
  $mdBottomSheet,
  nsPermissions,
  acpAnalytics,
  acpMedia
) {
  var vm = nsComponentDecorator(this),
    flowTitle;
  vm.cardsLabel = '';
  vm.component = 'virtual-cards-landing';
  vm.gaCategory = 'ACP_Virtual_Accounts';
  vm.cards = [];
  vm.card;
  vm.editNickName = false;
  vm.$onValue(acpCoreDispatcher.virtualCard.virtualCardsUpdate, updateUI);
  vm.$onValue(acpCoreDispatcher.card.cardsUpdate, getPhysicalCards);

  vm.openCardDetailPopup = function (id) {
    nsInPageFlow.open({
      flowTitle: flowTitle,
      template:
        '<acp-virtual-card-detail type="' +
        ACP_VIRTUAL_CARDS_CONSTANTS.CARD_TYPE.ACTIVE +
        '" id="' +
        id +
        '"></acp-virtual-card-detail>'
    });
  };

  vm.filter = function (type) {
    vm.cards = acpVirtualCardsModel.getFilteredCard(type);
  };

  vm.cardAssociationCheck = function (card, type) {
    return card.association === type;
  };

  vm.openNicknameDialog = function () {
    if (acpMedia('mobile')) {
      $mdBottomSheet.show({
        template: acpvirtualCardsAddBottomSheetTemplate,
        controller: function () {
          return vm;
        },
        controllerAs: 'vm'
      });
    } else {
      $mdDialog.show({
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        template: acpvirtualCardsAddDialogTemplate,
        controller: function () {
          return vm;
        },
        controllerAs: 'vm'
      });
    }
  };

  vm.addVirtualCard = function () {
    vm.editNickName = false;
    vm.card = undefined;
    vm.openNicknameDialog();
  };

  vm.editVirtualCard = function (cardData) {
    const card = acpVirtualCardsModel.getIndividualCard(cardData.id);
    vm.editNickName = true;
    vm.card = card;
    vm.openNicknameDialog();
  };

  vm.close = function () {
    $mdBottomSheet.hide();
    $mdDialog.hide();
    acpAnalytics.sendEvent(
      'ACP_Virtual_Accounts',
      'Cancel_Clicked',
      'Create_Virtual_Account'
    );
  };

  vm.deleteVirtualCard = function (cardData) {
    const card = acpVirtualCardsModel.getIndividualCard(cardData.id);
    vm.card = acpVirtualCardsModel.decryptCardData(true, card);
    if (acpMedia('mobile')) {
      $mdBottomSheet.show({
        template: acpVirtualCardsDetailBottomSheetTemplate,
        controller: function () {
          return vm;
        },
        controllerAs: 'vm'
      });
    } else {
      $mdDialog.show({
        template: acpVirtualCardsDetailDialogTemplate,
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        clickOutsideToClose: true,
        fullscreen: true
      });
    }
  };

  vm.closeConfirm = async function () {
    vm.busy = true;
    try {
      await acpVirtualCardsModel.closeVirtualCard(vm.card.id);
      await acpVirtualCardsModel.resetVirtualInfo();
      $mdDialog.hide();
      $mdBottomSheet.hide();
      acpCoreDispatcher.toast.show.emit({
        toastClass: 'success',
        content:
          'virtual-account-deleted-toast:components/virtual-cards-landing',
        hideDelay: 4000
      });
    } finally {
      vm.busy = false;
    }
  };

  vm.showActivationButton = function () {
    return vm.permissions && vm.permissions.isVirtualCardOrderAllowed;
  };

  vm.maskUnmaskCard = function (cardData, mask, index) {
    cardData = acpVirtualCardsModel.decryptCardData(!mask, cardData);
    cardData.masked = mask;
    vm.cards[index] = cardData;
  };

  function setVirtualCardData() {
    vm.cards = acpVirtualCardsModel.getFilteredCard(
      ACP_VIRTUAL_CARDS_CONSTANTS.CARD_TYPE.ACTIVE
    );
    vm.hasActiveCards = vm.cards.length ? true : false;
    vm.hasInactiveCards = acpVirtualCardsModel.getFilteredCard(
      ACP_VIRTUAL_CARDS_CONSTANTS.CARD_TYPE.INACTIVE
    ).length
      ? true
      : false;
    vm.addButton = acpVirtualCardsModel.canUserAddCard();
  }

  function init() {
    vm.busy = true;

    acpVirtualCardsModel
      .getAllVirtualCards()
      .then(setVirtualCardData)
      .finally(function () {
        vm.busy = false;
      });

    content
      .getKey('virtual-card', 'components/virtual-cards-landing')
      .then(function (data) {
        flowTitle = data.copy;
      });

    // Global variable to change active accounts and inactive accounts in the label.
    content.getKey('virtual-card', 'core/variables').then(function (data) {
      vm.cardsLabel = data.copy.toLowerCase();
    });
    nsPermissions
      .requestPermissions([
        'isDirectCDDA',
        'isCDDAEligibleForOrderCard',
        'isVirtualCardOrderAllowed'
      ])
      .then(function (permissions) {
        vm.permissions = permissions;
      });
    getPhysicalCards();
  }

  function updateUI() {
    $mdDialog.hide();
    setVirtualCardData();
  }

  function getPhysicalCards() {
    acpVirtualCardsModel
      .getAllPhysicalCards()
      .then(function (cards) {
        var activeCards = acpVirtualCardsModel.getFilteredCard('active', cards);
        vm.hasPrimaryCard = cards && cards.length && activeCards.length;
      })
      .finally(function () {
        vm.busy = false;
      });
  }

  init();
}

var acpVirtualCards = {
  controller: AcpVirtualCardsComponentCtrl,
  controllerAs: 'vm',
  template: acpVirtualCardsTemplate,
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  require: {}
};

export default acpVirtualCards;
