import ng from 'angular';
import acpCore from 'core';
import acpVirtualCardsLandingComponent from './acp-virtual-cards-landing-component';
import {
  acpVirtualCardDetailComponent,
  AcpVirtualCardDetailComponentCtrl
} from './acp-virtual-card-detail-component';
import acpVirtualCardsDomainComponentCtrl from 'components/virtual-cards-domain';
import acpVirtualCardsBottomLinksComponentCtrl from 'components/virtual-cards-bottom-links';
import acpPanCvcBlockComponent from 'components/pan-cvc-block';
import acpVirtualCardsPanCvcComponent from './acp-virtual-cards-pan-cvc-component';
import acpVirtualCards from 'components/virtual-cards';

import acpVirtualCardsLandingContent from './virtual-cards-landing-content.yml';
import acpVirtualCardDetailContent from './virtual-card-detail-content.yml';
import acpVirtualCardsPanCvcContent from './virtual-cards-pan-cvc-content.yml';
import acpUnsafeHtmlFilter from 'shared/angularjs/filters/acp-unsafe-html';

import './styles/${theme}/core.scss';

var component = ng
  .module('acp.component.virtual-cards-landing', [
    acpCore.name,
    acpUnsafeHtmlFilter.name,
    acpVirtualCardsDomainComponentCtrl.name,
    acpVirtualCardsBottomLinksComponentCtrl.name,
    acpVirtualCards.name,
    acpPanCvcBlockComponent.name
  ])
  .component('acpVirtualCardsLanding', acpVirtualCardsLandingComponent)
  .component('acpVirtualCardDetail', acpVirtualCardDetailComponent)
  .component('acpVirtualCardsPanCvc', acpVirtualCardsPanCvcComponent)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put(
        'components/virtual-cards-landing',
        acpVirtualCardsLandingContent
      );
      contentSectionCache.put(
        'components/virtual-card-detail',
        acpVirtualCardDetailContent
      );
      contentSectionCache.put(
        'components/virtual-cards-pan-cvc',
        acpVirtualCardsPanCvcContent
      );
    }
  );

import templates from './templates';
templates(component);

export { AcpVirtualCardDetailComponentCtrl };
export default component;
