import acpVirtualCardsPanCvcTemplate from './templates/acp-virtual-cards-pan-cvc.html';

// @ngInject
function AcpVirtualCardsComponentCtrl(
  nsComponentDecorator,
  acpCoreDispatcher,
  $nsDialog,
  acpVirtualCardsModel
) {
  var vm = nsComponentDecorator(this);
  vm.$onValue(
    acpCoreDispatcher.authenticate.isCvcUpdated,
    closeDialogOnCvcSuccess
  );

  vm.cancel = function() {
    $nsDialog.cancel();
  };

  function closeDialogOnCvcSuccess(data) {
    //set cvc flag's value after isCvcUpdated event is triggered
    acpVirtualCardsModel.isCvcAuthed(data);
    if (data.cvc) {
      $nsDialog.hide();
    }
  }
}

var acpVirtualCards = {
  controller: AcpVirtualCardsComponentCtrl,
  controllerAs: 'vm',
  template: acpVirtualCardsPanCvcTemplate,
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  require: {}
};

export default acpVirtualCards;
