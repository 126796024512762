import ng from 'angular';
import { VirtualCard } from 'components/virtual-cards';
import { AcpMedia } from 'core';
import acpvirtualCardsAddBottomSheetTemplate from './templates/acp-virtual-cards-add-bottom-sheet.html';
import acpvirtualCardsAddDialogTemplate from './templates/acp-virtual-cards-add-dialog.html';
import acpVirtualCardsDetailBottomSheetTemplate from './templates/acp-virtual-cards-detail-bottom-sheet.html';
import acpVirtualCardsDetailDialogTemplate from './templates/acp-virtual-cards-detail-dialog.html';
import acpVirtualCardDetailTemplate from './templates/acp-virtual-cards-detail.html';

export class AcpVirtualCardDetailComponentCtrl
  implements nsUtils.NsComponentController {
  displayName: string;
  card: VirtualCard;
  displayFlag = true;
  editNickName = true;
  isCardActivated = false;
  busy = false;
  type: string;
  id: string;
  isVirtualCardOrderAllowed: boolean;

  constructor(
    nsComponentDecorator,
    private acpVirtualCardsModel,
    private acpCoreDispatcher,
    private $mdDialog: ng.material.IDialogService,
    private nsInPageFlow,
    private acpUserModel,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private acpMedia: AcpMedia,
    private ACP_VIRTUAL_CARDS_CONSTANTS,
    private nsPermissions: nsUtils.NsPermissionsService
  ) {
    'ngInject';

    // If methods such as $onValue and $tie are needed:
    nsComponentDecorator(this, this);
  }

  async $onInit() {
    // Perform initialization here
    await this.requestPermissions();
    this.signalListen();
    this.dataInitialization();
    this.isCardActivated =
      this.type === this.ACP_VIRTUAL_CARDS_CONSTANTS.CARD_TYPE.ACTIVE;
  }

  handleHiddenCardDetail(): void {
    if (this.isVirtualCardOrderAllowed) {
      this.setCvcPan(this.displayFlag);
      this.displayFlag = !this.displayFlag;
    }
  }

  async inactiveVirtualCard(): Promise<void> {
    if (this.busy) {
      return;
    }
    this.busy = true;
    try {
      this.card = await this.acpVirtualCardsModel.closeVirtualCard(this.id);
      await this.acpVirtualCardsModel.resetVirtualInfo();
      this.nsInPageFlow.close();
      this.close();
      this.acpCoreDispatcher.toast.show.emit({
        toastClass: 'success',
        content:
          'virtual-account-closed-toast:components/virtual-cards-landing',
        hideDelay: 4000
      });
    } finally {
      this.busy = false;
    }
  }

  openNickNameModal(): void {
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet.show({
        template: acpvirtualCardsAddBottomSheetTemplate,
        controller: this.getPopupController()
      });
    } else {
      this.$mdDialog.show({
        parent: ng.element(document.body),
        clickOutsideToClose: true,
        template: acpvirtualCardsAddDialogTemplate,
        controller: this.getPopupController()
      });
    }
  }

  cardAssociationCheck(type: string): boolean {
    return this.card && this.card.association === type;
  }

  closeVirtualCardDetail(): void {
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet.show({
        template: acpVirtualCardsDetailBottomSheetTemplate,
        controller: this.getPopupController()
      });
    } else {
      this.$mdDialog.show({
        template: acpVirtualCardsDetailDialogTemplate,
        controller: this.getPopupController(),
        clickOutsideToClose: true,
        fullscreen: true
      });
    }
  }

  closeConfirm(): void {
    this.inactiveVirtualCard();
  }

  $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  private getPopupController() {
    const controllerPopUp = /*@ngInject*/ ($scope) => {
      $scope.vm = this;
    };
    return controllerPopUp;
  }

  private close(): void {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }

  private async dataInitialization(): Promise<void> {
    this.card = await this.acpVirtualCardsModel.getIndividualCard(this.id);
    this.setCvcPan(false);
    await this.acpUserModel.get();
  }

  private signalListen(): void {
    this.$onValue(this.acpCoreDispatcher.profile.user.updated, (user) => {
      this.getDisplayName(user);
    });
    this.$onValue(
      this.acpCoreDispatcher.virtualCard.virtualCardNicknameUpdated,
      (data) => {
        this.nicknameUpdated(data);
      }
    );
  }

  private getDisplayName(user): void {
    this.displayName =
      user.primary_card_owner && user.primary_card_owner._display_name;
  }

  private nicknameUpdated(data): void {
    this.card.nick_name = data && data.updatedNickname;
  }

  private setCvcPan(decryptionFlag: boolean): void {
    const finalObject = this.acpVirtualCardsModel.decryptCardData(
      decryptionFlag,
      this.card
    );
    this.card.finalPan = finalObject.finalPan;
    this.card.finalCvc = finalObject.finalCvc;
    this.card.panLastFourDigit = finalObject.panLastFourDigit;
  }

  private requestPermissions(): Promise<nsUtils.NsPermissionResult> {
    return this.nsPermissions
      .requestPermissions(['isVirtualCardOrderAllowed'])
      .then(this.updatePermissions.bind(this));
  }

  private updatePermissions(permissions?: nsUtils.NsPermissionResult): void {
    this.isVirtualCardOrderAllowed = permissions.isVirtualCardOrderAllowed;
  }

  // These are needed to satisfy TSC. The real implementation comes from `nsComponentDecorator(this, this)`
  // public $tie?<T>(property: string, signal: nsUtils.NsSignal<T>);
  // public $tiePermissions?(property: string, permissions: string[]);
}

export const acpVirtualCardDetailComponent: ng.IComponentOptions = {
  bindings: {
    id: '@',
    type: '@'
  }, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpVirtualCardDetailComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpVirtualCardDetailTemplate
};
